import { Controller } from "@hotwired/stimulus";
import { parseHTML } from "../helpers/dom_helpers.js";

export default class extends Controller {
  static values = { term: String };

  connect() {
    const terms = this.termValue.split(" ");

    for (const node of this.#textNodes()) {
      const text = node.nodeValue;

      const highlightedText = terms.reduce((text, term) => {
        return text.replaceAll(
          term,
          `<span class="bg-yellow-100 text-yellow-900">${term}</span>`,
        );
      }, text);

      if (highlightedText !== undefined && highlightedText != text) {
        node.parentElement.replaceChild(parseHTML(highlightedText), node);
      }
    }
  }

  #textNodes() {
    const textNodes = [];

    const crawl = (element) => {
      if (element.nodeType === Node.TEXT_NODE) {
        textNodes.push(element);
      } else {
        for (const child of element.childNodes) {
          crawl(child);
        }
      }
    };

    crawl(this.element);

    return textNodes;
  }
}
