import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import { debounce } from "../helpers/timing_helpers";

export default class extends Controller {
  static targets = ["input", "datalist", "selected"];
  static values = { url: String };

  #debouncedRequest;

  connect() {
    this.#debouncedRequest = debounce(this.#request.bind(this), 300);
  }

  update(ev) {
    const term = ev.currentTarget.value;

    this.#debouncedRequest(term);
  }

  show() {
    this.datalistTarget.classList.remove("hidden");
  }

  reset() {
    this.selectedTarget.innerHTML = "";
    this.datalistTarget.innerHTML = "";
    this.inputTarget.value = "";
    this.inputTarget.classList.remove("hidden");

    requestAnimationFrame(() => {
      this.inputTarget.focus();

      this.dispatch("select");
    });
  }

  select(ev) {
    this.inputTarget.classList.add("hidden");
    this.selectedTarget.innerHTML = ev.currentTarget.querySelector(
      "template[name=selected]",
    ).innerHTML;
    this.hide();

    this.dispatch("select");
  }

  hide() {
    this.datalistTarget.classList.add("hidden");
  }

  #request(term) {
    if (term === "") {
      this.hide();
      this.datalistTarget.innerHTML = "";

      return;
    }

    this.show();

    get(this.urlValue + "?term=" + term, {
      responseKind: "turbo-stream",
    });
  }
}
