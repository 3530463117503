import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]
  static classes = ["active"]

  toggle(ev) {
    this.linkTargets.forEach(link => {
      link.classList.remove(...this.activeClasses)
    })

    ev.currentTarget.classList.add(...this.activeClasses)
  }
}
