import { Controller } from "@hotwired/stimulus"

const FORMATS = {
  "HH:MM": (datetime) => {
    return datetime.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit"
    })
  },
  "day_and_time": (datetime) => {
    if (datetime.toDateString() == today().toDateString()) {
      const localizedTime = datetime.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit"
      })

      return `Today, ${localizedTime}`
    }

    if (datetime.toDateString() == yesterday().toDateString()) {
      const localizedTime = datetime.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit"
      })

      return `Yesterday, ${localizedTime}`
    }

    return datetime.toLocaleTimeString(navigator.language, {
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit"
    })
  },
  "distance_of_time_in_words": (datetime) => {
    const diffInSeconds = (Date.now() - datetime.getTime()) / 1000

    if (diffInSeconds < 60) {
      return "less than a minute"
    } else if (diffInSeconds < 180) {
      return "a couple of minutes"
    } else if (diffInSeconds < 3600) {
      return (diffInSeconds / 60).toFixed() + " minutes"
    } else if (diffInSeconds < 86400) {
      const hours = (diffInSeconds / 3600).toFixed()

      if (hours == 1) {
        return "about one hour"
      } else {
        return "about " + hours + " hours"
      }
    } else {
      const days = (diffInSeconds / 86400).toFixed()

      if (days == 1) {
        return "about one day"
      } else {
        return "about " + days + " days"
      }
    }
  }
}

function today() {
  return new Date()
}

function yesterday() {
  const date = today()

  date.setDate(date.getDate() - 1)

  return date
}

export default class extends Controller {
  static values = {
    iso8601: String,
    format: String
  }

  connect() {
    const datetime = new Date(this.iso8601Value)

    this.element.innerText = FORMATS[this.formatValue](datetime)
    this.element.title = datetime.toString()
  }
}
