import { Controller } from "@hotwired/stimulus";
import { pageIsTurboPreview } from "../helpers/turbo_helpers";

const REAPPEAR_AFTER_HIDDEN_TIMEOUT = 60_000;

/**
 * Realtime is a thin abstraction on top of turbo streams. There is a server side to
 * this abstraction (app/lib/realtime) and a client side (this stimulus controller).
 *
 * The goal is not to provide features on top of action cable, but to treat is a dependency
 * that the app uses instead of it being the app itself.
 */
export default class extends Controller {
  #connectedOnce = false;
  #disconnectObserver;
  #pageHiddenAt;

  connect() {
    if (pageIsTurboPreview()) {
      return;
    }

    this.element.insertAdjacentHTML("afterend", this.element.innerHTML);
    this.streamElement = this.element.nextSibling;

    this.#disconnectObserver = observeAttributeChange(
      this.streamElement,
      this.#onAttributeChange.bind(this),
    );
  }

  disconnect() {
    this.streamElement?.remove();
    this.#disconnectObserver?.call();
  }

  visibilityChanged() {
    if (document.visibilityState === "visible") {
      if (this.#pageHiddenForTooLong()) {
        this.dispatch("reappear");
      }

      this.#pageHiddenAt = null;
    } else {
      this.#pageHiddenAt = Date.now();
    }
  }

  online() {
    // Trigger reconnection attempt when the browser comes back from offline
    //
    // I copied this code from campfire, but it *feels* like it should belong in actioncable itself.
    this.streamElement.subscription.consumer.connection.monitor.visibilityDidChange();
  }

  #onAttributeChange(attributeName) {
    if (attributeName === "connected") {
      if (this.#isConnected()) {
        this.dispatch(this.#connectedOnce ? "reconnect" : "connect");
      } else {
        this.dispatch("disconnect");
      }

      this.#connectedOnce = true;
    }
  }

  #isConnected() {
    return this.streamElement.hasAttribute("connected");
  }

  #pageHiddenForTooLong() {
    return (
      this.#pageHiddenAt &&
      Date.now() - this.#pageHiddenAt > REAPPEAR_AFTER_HIDDEN_TIMEOUT
    );
  }
}

function observeAttributeChange(element, callback) {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type !== "attributes") {
        return;
      }

      callback(mutation.attributeName);
    });
  });

  observer.observe(element, { attributes: true });

  return () => observer.disconnect();
}
