import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

const POLL_INTERVAL_MS = 5000;
const MAX_ATTEMPTS = 3;

export default class extends Controller {
  static values = { ticketId: String };
  static classes = ["pending", "failed"];
  static targets = ["deliveryStatus", "uploadProgressBar"];
  static outlets = ["messages"];

  #timeout;
  #attempts;

  connect() {
    this.#attempts = 0;
    this.#scheduleRequest();
  }

  disconnect() {
    clearTimeout(this.#timeout);
  }

  updateUploadProgressBar(event) {
    if ((event.detail.clientId = this.element.id)) {
      this.uploadProgressBarTarget.classList.toggle(
        "hidden",
        event.detail.percent === 100
      );

      this.uploadProgressBarTarget.value = event.detail.percent;

      this.messagesOutlet.autoScroll();
    }
  }

  #scheduleRequest() {
    this.#attempts += 1;

    if (this.#attempts > MAX_ATTEMPTS) {
      return this.#renderMessageFailed();
    }

    this.#timeout = setTimeout(this.#fetchMessage.bind(this), POLL_INTERVAL_MS);
  }

  async #fetchMessage() {
    const ticketId = this.ticketIdValue;
    const clientId = this.element.id;

    try {
      const res = await get(`/inbox/tickets/${ticketId}/messages/${clientId}`, {
        responseKind: "turbo-stream",
      });

      if (res.statusCode !== 200) {
        this.#scheduleRequest();
      }
    } catch (networkError) {
      this.#attempts = 0;
      this.#scheduleRequest();
    }
  }

  #renderMessageFailed() {
    this.element.classList.remove(this.pendingClass);
    this.element.classList.add(this.failedClass);
    this.deliveryStatusTarget.innerText = "Failed";
  }
}
