import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["toggle"]

  async connect() {
    if (await Telescope.Notification.Sound.isEnabled()) {
      this.toggleOutlet.turnOn({ dispatchEvent: false })
    }
  }

  enable() {
    Telescope.Notification.Sound.enable()
  }

  disable() {
    Telescope.Notification.Sound.disable()
  }
}
