import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["on", "off"]

  toggle() {
    this.isOn() ? this.turnOff({ dispatchEvent: true }) : this.turnOn({ dispatchEvent: true })
  }

  turnOn(opts) {
    this.element.classList.add(this.onClass);
    this.element.classList.remove(this.offClass);

    if (opts?.dispatchEvent) {
      this.dispatch("on")
    }
  }

  turnOff(opts) {
    this.element.classList.remove(this.onClass);
    this.element.classList.add(this.offClass);

    if (opts?.dispatchEvent) {
      this.dispatch("off")
    }
  }

  isOn() {
    return this.element.classList.contains(this.onClass)
  }
}
