export class RenderContext {
  #value

  constructor() {
    this.#value = "initializing"
  }

  connected() {
    this.#value = "stream"
  }

  async setPagination(callback) {
    const originalValue = this.#value
    this.#value = "pagination"

    await callback()

    this.#value = originalValue
  }

  isStream() {
    return this.#value === "stream"
  }
}
