import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "authCode", "form", "pleaseWait", "somethingWentWrong" ]
  static values = { url: String, trackerUrl: String }

  connect() {
    this.#loadFacebookSDK()
  }

  login() {
    const config = {
      config_id: "1301519504156402",
      response_type: "code",
      override_default_response_type: true
    }

    FB.login((response) => {
      post(this.trackerUrlValue, { body: JSON.stringify(response) })

      if (response.authResponse) {
        this.pleaseWaitTarget.classList.remove("hidden")

        post(this.urlValue, { body: JSON.stringify(response.authResponse) })
          .then(() => {
            Turbo.visit(location.href, { action: "replace" })
          })
          .catch(() => {
            this.pleaseWaitTarget.classList.add("hidden")
            this.somethingWentWrongTarget.classList.remove("hidden")
          })
      } else {
        this.somethingWentWrongTarget.classList.remove("hidden")
      }
    }, config);
  }

  async submitFakeAuthCode() {
    const fakeAuthResponse = { code: "fake" }

    await post(this.trackerUrlValue, { body: JSON.stringify(fakeAuthResponse) })

    await post(this.urlValue, { body: JSON.stringify(fakeAuthResponse) })

    Turbo.visit(location.href, { action: "replace" })
  }

  #loadFacebookSDK() {
    window.fbAsyncInit = () => {
      FB.init({
        appId: this.#appId(),
        cookie: true,
        xfbml: true,
        version: 'v20.0'
      });
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk')); 
  }

  #appId() {
    return document.querySelector("meta[name=facebook-app-id]").content
  }
}