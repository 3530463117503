import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["instructionsToUnblock"];
  static outlets = ["toggle"];

  async connect() {
    if (Telescope.Notification.Browser.isEnabled()) {
      this.toggleOutlet.turnOn({ dispatchEvent: false });
    }
  }

  async enable() {
    if (await Telescope.Notification.Browser.enable()) {
      Telescope.Notification.Browser.show({
        title: "Ok, got it",
        body: "We'll show a notification like this one when a ticket arrives.",
      });
    } else {
      this.toggleOutlet.turnOff({ dispatchEvent: false });

      this.instructionsToUnblockTarget.classList.remove("hidden");
    }
  }

  disable() {
    Telescope.Notification.Browser.disable();
  }
}
