import { Controller } from "@hotwired/stimulus";

const HIDDEN_CLASS = "hidden";

export default class extends Controller {
  navigate(event) {
    const navigateTo = this.#resolveNavigateTo(event.currentTarget);

    if (!navigateTo) {
      return;
    }

    const wizardStepContainer = this.element.querySelector(
      `[data-wizard-step="${navigateTo}"]`
    );

    this.#activeStepElement().classList.add(HIDDEN_CLASS);
    wizardStepContainer.classList.remove(HIDDEN_CLASS);
  }

  #resolveNavigateTo(elm) {
    const wizardNavigateTo = elm.dataset.wizardNavigateTo;
    if (wizardNavigateTo) {
      return wizardNavigateTo;
    }

    const inputName = elm.dataset.wizardNavigateToInput;
    let input = document.querySelector(`[name="${inputName}"]`);

    if (input && input.type === "radio") {
      input = document.querySelector(`[name="${inputName}"]:checked`);
    }

    return input?.value;
  }

  #activeStepElement() {
    return Array.from(this.element.querySelectorAll("[data-wizard-step]")).find(
      (element) => {
        return !element.classList.contains(HIDDEN_CLASS);
      }
    );
  }
}
