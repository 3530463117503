import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "hidden"

export default class extends Controller {
  static values = { visible: String }

  connect() {
    if (this.hasVisibleValue) {
      if (this.visibleValue === "has-assignment" && this.#currentAssignmentId() && this.#currentAssignmentStatus() == "active") {
        this.element.classList.remove(HIDDEN_CLASS)
      }
    }
  }

  #currentAssignmentId() {
    return document.querySelector("meta[name=current-assignment-id]")?.content
  }

  #currentAssignmentStatus() {
    return document.querySelector("meta[name=current-assignment-status]")?.content
  }
}
