export function nextRenderTick() {
  return new Promise(resolve => {
    setTimeout(resolve, 100)
  })
}

export function parseHTML(html) {
  const template = document.createElement("template")

  template.innerHTML = html

  return template.content
}

export async function keepScroll(element, callback) {
  const oldScrollHeight = element.scrollHeight
  const oldScrollTop    = element.scrollTop

  await callback()

  const newScrollHeight = element.scrollHeight

  element.scrollTop = oldScrollTop + (newScrollHeight - oldScrollHeight)
}
