export function setQueryParam(urlString, name, value) {
  const url = new URL(urlString)
  const search = new URLSearchParams(url.search)

  if (search.has(name)) {
    search.delete(name)
  }

  search.append(name, value)

  url.search = search

  return url.toString()
}