export class Restrictions {
  #rules
  #messageTarget
  #attachmentTarget
  #errorTarget
  #submitTarget

  constructor({ rules, messageTarget, attachmentTarget, errorTarget, submitTarget }) {
    this.#rules = rules
    this.#messageTarget = messageTarget
    this.#attachmentTarget = attachmentTarget
    this.#errorTarget = errorTarget
    this.#submitTarget = submitTarget

    this.messageLocked = false
    this.attachmentsLocked = false
    this.error = null
  }

  isInvalid() {
    return this.error != null
  }

  acceptsInlineAttachments() {
    return this.#rules.max_inline_attachments_per_message != 0
  }

  apply(message, filelist) {
    this.#reset()

    const applyRule = (name, callback) => {
      const rule = this.#rules[name]

      if (rule != null) {
        callback({ rule, message, filelist })
      }
    }

    applyRule("max_attachments_per_message", this.#maxAttachmentsPerMessage.bind(this))
    applyRule("max_inline_attachments_per_message", this.#maxInlineAttachmentsPerMessage.bind(this))
    applyRule("allowed_attachment_extensions", this.#allowedAttachmentExtensions.bind(this))
    applyRule("forbidden_attachment_extensions", this.#forbiddenAttachmentExtensions.bind(this))
    applyRule("max_size_per_attachment", this.#maxSizePerAttachment.bind(this))
    applyRule("max_size_all_attachments", this.#maxSizeAllAttachments.bind(this))
    applyRule("allowed_caption_extensions", this.#allowedCaptionExtensions.bind(this))

    this.#render()
  }

  #reset() {
    this.messageLocked = false
    this.attachmentsLocked = false
    this.error = null
  }

  #render() {
    this.#attachmentTarget.disabled = this.attachmentsLocked
    this.#messageTarget.classList.toggle("disabled", this.messageLocked)

    if (this.error) {
      this.#errorTarget.innerText = this.error
      this.#errorTarget.classList.remove("hidden")
      this.#submitTarget.disabled = true
    } else {
      this.#errorTarget.classList.add("hidden")
      this.#submitTarget.disabled = false
    }
  }

  #maxAttachmentsPerMessage({ rule, filelist }) {
    if (filelist.length === rule) {
      this.attachmentsLocked = true
    } else if (filelist.length > rule) {
      this.error = "There are too many attachments. Please remove one of the files before sending the message."
    }
  }

  #maxInlineAttachmentsPerMessage({ rule, message }) {
    const inlineAttachmentsCount = message.querySelectorAll('[data-trix-attachment]').length

    if (inlineAttachmentsCount > rule) {
      this.error = "Please remove one of the images before sending the message."
    }
  }

  #allowedAttachmentExtensions({ rule, filelist }) {
    const allFilesAllowed = getExtensions(filelist).every(ext => rule.includes(ext))

    if (! allFilesAllowed) {
      this.error = `Invalid attachment. The file must be one of the following: ${rule.join(", ")}`
    }
  }

  #forbiddenAttachmentExtensions({ rule, filelist }) {
    const allFilesAllowed = getExtensions(filelist).every(ext => !rule.includes(ext))

    if (! allFilesAllowed) {
      this.error = `Invalid attachment. Files cannot be one of: ${rule.join(", ")}`
    }
  }

  #maxSizePerAttachment({ rule, filelist }) {
    for (const file of filelist) {
      const maxSize = rule[getExtension(file)]

      if (file.size > maxSize) {
        const maxSizeMB = maxSize / 1024 / 1024
        const fileSizeMB = file.size / 1024 / 1024

        this.error = `Max attachment size exceeded - ${maxSizeMB.toFixed(2)}MB max, file is ${fileSizeMB.toFixed(2)}MB`
      }
    }
  }

  #maxSizeAllAttachments({ rule, message, filelist }) {
    let totalSize = 0

    for (const file of filelist) { totalSize += file.size }

    for (const figure of message.querySelectorAll('[data-trix-attachment]')) {
      const trixAttachment = JSON.parse(figure.dataset.trixAttachment)

      totalSize += trixAttachment["filesize"]
    }

    if (totalSize > rule) {
      const maxSizeMB = rule / 1024 / 1024
      const totalSizeMB = totalSize / 1024 / 1024

      this.error = `Total attachment size exceeded - ${maxSizeMB.toFixed(2)}MB max, you have ${totalSizeMB.toFixed(2)}MB`
    }
  }

  #allowedCaptionExtensions({ rule, message, filelist }) {
    for (const extension of getExtensions(filelist)) {
      const messageIsEmpty = message.value == ""
      const captionable = rule.includes(extension)

      if (messageIsEmpty && !captionable) {
        this.messageLocked = true
      }

      if (!messageIsEmpty && !captionable) {
        this.error = `You cannot send the message with both the text and the attachment. Please remove one of them.`
      }
    }
  }
}

function getExtensions(filelist) {
  return filelist.map(getExtension)
}

function getExtension(file) {
  return `.${file.name.split(".").pop()}`
}
