import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggle() {
    if (! this.element.open) {
      requestAnimationFrame(this.#closeOnNextClick.bind(this));
    }
  }

  #closeOnNextClick() {
    document.body.addEventListener("click", (ev) => {
      if (this.#isTrigger(ev.target)) {
        // Clicking the trigger again already closes the dropdown.
        return
      }

      this.element.open = false;
    }, { once: true });
  }

  #isTrigger(elm) {
    const trigger = this.element.querySelector("summary")

    return trigger === elm || trigger.contains(elm)
  }
}
