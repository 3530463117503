const LOCAL_STORAGE_KEY = "browser_notification";

export class NotificationBrowser {
  #notification;

  async enable() {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      localStorage.setItem(LOCAL_STORAGE_KEY, "enabled");

      return true;
    }

    return false;
  }

  disable() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }

  isEnabled() {
    return localStorage.getItem(LOCAL_STORAGE_KEY) && this.#isAuthorized();
  }

  show({ title, body }) {
    if (!this.isEnabled()) {
      return;
    }

    this.#notification = new Notification(title, { body });
  }

  hide() {
    this.#notification?.close();
  }

  #isAuthorized() {
    return Notification.permission === "granted";
  }
}
