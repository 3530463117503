const SPOTLIGHT_CLASS = ["bg-yellow-100", "ring", "ring-yellow-400"]
const SPOTLIGHT_TRANSITION_CLASSES = ["transition-all", "duration-[1500ms]"]
const TRANSITION_DURATION_MS = 1500

document.addEventListener("turbo:load", (ev) => {
  const { url } = ev.detail
  const parsedUrl = new URL(url)
  const queryString = new URLSearchParams(parsedUrl.search)
  const spotlightDomId = queryString.get("spotlight")

  if (! spotlightDomId) {
    return
  }

  const spotlightElm = document.getElementById(spotlightDomId)

  if (! spotlightElm) {
    return
  }

  spotlightElm.classList.add(...SPOTLIGHT_CLASS)

  requestAnimationFrame(() => {
    spotlightElm.scrollIntoView()
    spotlightElm.classList.add(...SPOTLIGHT_TRANSITION_CLASSES)

    requestAnimationFrame(() => {
      spotlightElm.classList.remove(...SPOTLIGHT_CLASS)

      setTimeout(() => {
        spotlightElm.classList.remove(...SPOTLIGHT_TRANSITION_CLASSES)
      }, TRANSITION_DURATION_MS)
    })
  })
})
