import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { entries: Object }

  connect() {
    for (const [entryId, agony] of Object.entries(this.entriesValue)) {
      document.querySelector(`[data-entry-id="${entryId}"]`).setAttribute("data-agony", agony)
    }
  }
}
