const MAX_RETRIES   = 10
const RETRY_DELAY   = 5_000
const RETRY_BACKOFF = 1_000

export class MessageSender {
  #url
  #content
  #files
  #copilot
  #clientId
  #retryCount = 0

  constructor({ url, content, files, copilot, clientId }) {
    this.#url      = url
    this.#content  = content
    this.#files    = files
    this.#copilot  = copilot
    this.#clientId = clientId
  }

  send() {
    const formdata = new FormData()
    formdata.append("content", this.#content)
    formdata.append("client_id", this.#clientId)
    formdata.append("_retry_count", this.#retryCount)

    for (const file of this.#files) {
      formdata.append("attachments[]", file)
    }

    for (const [name, value] of Object.entries(this.#copilot.serialize())) {
      formdata.append(name, value)
    }

    this.#copilot.reset()

    const req = new XMLHttpRequest()
    req.open("POST", this.#url)
    req.setRequestHeader("X-CSRF-Token", document.querySelector("meta[name=csrf-token]").content)
    req.upload.addEventListener("progress", this.#uploadProgress.bind(this))

    req.addEventListener("readystatechange", () => {
      if (req.readyState === XMLHttpRequest.DONE) {
        if (isNetworkError(req)) {
          this.#scheduleNextRetry()
        }

        if (req.status === 422) {
          this.#handleComposerRestrictionError(JSON.parse(req.responseText))
        }
      }
    })

    req.send(formdata)
  }

  #uploadProgress(event) {
    if (event.lengthComputable) {
      const percent = Math.round((event.loaded / event.total) * 100)

      const uploadProgressEvent = new CustomEvent("message-upload-progress", { detail: { clientId: this.#clientId, percent } })

      document.dispatchEvent(uploadProgressEvent)
    }
  }

  #handleComposerRestrictionError({ error }) {
    window.alert(error)
  }

  #scheduleNextRetry() {
    if (this.#retryCount++ >= MAX_RETRIES) {
      // NOTE: pending-message controller picks up error and displays failed state to user.

      return
    }

    setTimeout(this.send.bind(this), RETRY_DELAY + (this.#retryCount * RETRY_BACKOFF))
  }
}

function isNetworkError(request) {
  return request.status === 0
}