import { Controller } from "@hotwired/stimulus"
import { setQueryParam } from "../helpers/url_helpers"

export default class extends Controller {
  refresh() {
    this.#refreshWithReason("refresh")
  }

  reconnect() {
    this.#refreshWithReason("reconnect")
  }

  reappear() {
    this.#refreshWithReason("reappear")
  }

  #refreshWithReason(reason) {
    let href = setQueryParam(location.href, "_refresh_reason", reason)

    for (const meta of document.querySelectorAll('meta[data-refresh-param]')) {
      href = setQueryParam(href, meta.name, meta.content)
    }

    Turbo.visit(href, { action: "replace" })
  }
}
