import { get } from "@rails/request.js";
import { keepScroll, parseHTML } from "../../helpers/dom_helpers.js";

const PAGINATION_PER_PAGE = 20;

export class Paginator {
  #container;
  #url;
  #renderContext;
  #loadPreviousPage;

  constructor(container, loadPreviousPage, url, renderContext) {
    this.#container = container;
    this.#loadPreviousPage = loadPreviousPage;
    this.#url = url;
    this.#renderContext = renderContext;
  }

  async loadPreviousPage() {
    const response = await get(
      this.#url + "?before=" + this.#oldestMessageId(),
    );
    const fragment = parseHTML(await response.html);
    const messages = fragment.querySelectorAll(".message");

    if (messages.length < PAGINATION_PER_PAGE) {
      this.#hideLoadPreviousPage();
    }

    // TODO: find something more reliable to be the starting cursor
    let prependCursor = this.#container.childNodes[1];

    this.#renderContext.setPagination(() => {
      keepScroll(this.#container, () => {
        for (const message of messages) {
          prependCursor.insertAdjacentElement("afterend", message);
          prependCursor = message;
        }
      });
    });
  }

  trimExcessMessages() {
    const amountOfMessages =
      this.#container.querySelectorAll(".message").length;
    const messagesToRemove = amountOfMessages - PAGINATION_PER_PAGE;

    if (messagesToRemove <= 0) {
      return;
    }

    this.#showLoadPreviousPage();

    for (let i = 0; i < messagesToRemove; i++) {
      this.#container.querySelector(".message").remove();
    }
  }

  #oldestMessageId() {
    const oldestMessage = this.#container.querySelector(".message");

    return oldestMessage.dataset.id;
  }

  #showLoadPreviousPage() {
    this.#loadPreviousPage.classList.remove("hidden");
  }

  #hideLoadPreviousPage() {
    this.#loadPreviousPage.classList.add("hidden");
  }
}
