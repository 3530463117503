import { Controller } from "@hotwired/stimulus"
import { currentAccountId } from "../helpers/current_helpers"

const HIDDEN_CLASS = "hidden"

export default class extends Controller {
  static values = { visible: Array, invisible: Array }

  connect() {
    if (this.#visibleToCurrentAccount()) {
      this.element.classList.remove(HIDDEN_CLASS)
    }
  }

  #visibleToCurrentAccount() {
    if (this.hasVisibleValue) {
      return this.visibleValue.find(id => id == currentAccountId())
    }

    if (this.hasInvisibleValue) {
      return !this.invisibleValue.find(id => id == currentAccountId())
    }
  }
}
