import { debounce } from "../../helpers/timing_helpers";

const AUTO_SCROLL_THREADSHOLD = 100;

export class ScrollManager {
  #container;
  #isAutoScrolling;
  #onAutoScrollingEnabled;

  constructor(container, { onAutoScrollingEnabled }) {
    this.#container = container;
    this.#isAutoScrolling = this.#isScrollBarNearEnd();
    this.#onAutoScrollingEnabled = onAutoScrollingEnabled;

    this.#detectAutoScrollingWhenUserManuallyMovesScrollBar();
  }

  autoScroll() {
    if (this.#isAutoScrolling) {
      this.scrollToBottom();

      return true;
    }

    return false;
  }

  scrollToBottom() {
    this.#container.scrollTop = this.#container.scrollHeight;
  }

  #detectAutoScrollingWhenUserManuallyMovesScrollBar() {
    this.#container.addEventListener(
      "scroll",
      debounce(() => {
        const wasAutoScrolling = this.#isAutoScrolling;
        this.#isAutoScrolling = this.#isScrollBarNearEnd();

        if (!wasAutoScrolling && this.#isAutoScrolling) {
          this.#onAutoScrollingEnabled();
        }
      }, 100),
    );
  }

  #isScrollBarNearEnd() {
    const { scrollHeight, scrollTop, clientHeight } = this.#container;
    const distanceScrolledFromEnd = scrollHeight - scrollTop - clientHeight;

    return distanceScrolledFromEnd < AUTO_SCROLL_THREADSHOLD;
  }
}
