const LOCAL_STORAGE_KEY = "notification_sound"

let _userHasInteractedWithPage = false
window.addEventListener("click", () => { _userHasInteractedWithPage = true }, { once: true })

export class NotificationSound {
  #audio
  #wasAbleToPlayAudioOnLoad

  constructor(url) {
    this.#audio = new Audio(url)
  }

  enable() {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, "enabled")

    this.playOnce()
  }

  disable() {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY)
  }

  playOnce() {
    return this.#audio.play()
  }

  async playRepeatUntil(stopCondition) {
    if (! await this.isEnabled()) {
      return
    }

    this.#audio.play()

    const playNextRepeat = () => {
      if (stopCondition()) {
        return
      }

      this.playRepeatUntil(stopCondition)
    }

    this.#audio.addEventListener("ended", playNextRepeat, { once: true })
  }

  async isEnabled() {
    if (! window.localStorage.getItem(LOCAL_STORAGE_KEY)) {
      return false
    }

    if (_userHasInteractedWithPage) {
      return true
    }

    if (this.#wasAbleToPlayAudioOnLoad !== undefined) {
      return this.#wasAbleToPlayAudioOnLoad
    }

    return (this.#wasAbleToPlayAudioOnLoad = await this.#isAbleToPlayAudio())
  }

  async #isAbleToPlayAudio() {
    try {
      await this.#playMuted()

      return true
    } catch(err) {
      // DOMException: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD
      return false
    }
  }

  async #playMuted() {
    this.#audio.volume = 0.0

    return this.playOnce().finally(() => {
      this.#audio.pause()
      this.#audio.currentTime = 0.0
      this.#audio.volume = 1.0
    })
  }
}
