import { NotificationSound } from "./notification/sound"
import { NotificationBrowser } from "./notification/browser"
import { NotificationPageTitle } from "./notification/page_title"

export class Notification  {
  constructor() {
    this.Sound = new NotificationSound(document.querySelector('meta[name=notification-sound-url]').content)
    this.Browser = new NotificationBrowser()
    this.PageTitle = new NotificationPageTitle('(!)')

    document.addEventListener("visibilitychange", this.#onVisibilityChange.bind(this))
  }

  show({ title, body }) {
    if (this.#userIsLookingAtTheBrowser()) {
      return
    }

    this.Browser.show({ title, body })
    this.PageTitle.show()
    this.Sound.playRepeatUntil(() => this.#userIsLookingAtTheBrowser())
  }

  #userIsLookingAtTheBrowser() {
    return document.visibilityState === "visible"
  }

  #onVisibilityChange() {
    if (this.#userIsLookingAtTheBrowser()) {
      this.PageTitle.hide()
      this.Browser.hide()
    }
  }
}
