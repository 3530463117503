export class NotificationPageTitle {
  #indicator

  constructor(indicator) {
    this.#indicator = indicator
  }

  show() {
    document.title = this.#indicator + ' ' + document.title
  }

  hide() {
    document.title = document.title.replace(this.#indicator + ' ', '')
  }
}
