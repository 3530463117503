import { get } from "@rails/request.js"

export class RefreshManager {
  #url
  #lastUpdatedAt

  constructor(url) {
    this.#url = url
    this.#lastUpdatedAt = null
  }

  messageTargetConnected(message) {
    const updatedAt = message.dataset.updatedAt

    if (! updatedAt) { return }
    if (updatedAt < this.#lastUpdatedAt) { return }

    this.#lastUpdatedAt = updatedAt
  }

  refresh(reason) {
    get(this.#url + "&since=" + this.#lastUpdatedAt + "&reason=" + reason, {
      responseKind: "turbo-stream"
    })
  }
}
